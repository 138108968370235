import { LessonConfig } from './types'

const GOALS_CONFIG: LessonConfig = {
    title: 'Goals',
    description: 'What do you want to achieve and how will you do so?',
    // visual: {
    //     type: 'image',
    //     imgSrc: 'coming-soon.jpg',
    //     width: 1280,
    //     height: 854,
    // },
    blocks: [
        {
            type: 'visual',
            content: {
                videoSrc: '/demos/Stretchandsmartgoal.mp4',
                type: 'video',
                width: 1920,
                height: 1080,
                maxWidth: 1000
            }
        },
        {
            type: 'bullets',
            content: ['Stretch Goal: An overall goal that you hope to achieve', 'SMART Goal: Break down your goals so that they are manageable. Make it Specific, Measurable, Achievable, Relevant, and Time-bound'],
        },
    ],
    featuresUnlocked: ['goals'],
    challenges: [
        {
            text: 'Create a stretch goal',
            id: 'stretch-goal',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },

        {
            text: 'Create a SMART goal',
            id: 'smart-goal',
            rewardAmount: 50,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ],
}

export default GOALS_CONFIG
