import { z } from 'zod'

export const SourceEnum = z.enum(['apple'])
export type Source = z.infer<typeof SourceEnum>
// export type ParticipationStatus = 'NEEDS-ACTION' | 'ACCEPTED' | 'DECLINED' | 'TENTATIVE' | 'DELEGATED' | 'COMPLETED' | 'IN-PROCESS'

// export type ParticipationRole = 'CHAIR' | 'REQ-PARTICIPANT' | 'OPT-PARTICIPANT' | 'NON-PARTICIPANT'
export const ParticipationStatusEnum = z.enum(['NEEDS-ACTION', 'ACCEPTED', 'DECLINED', 'TENTATIVE', 'DELEGATED', 'COMPLETED', 'IN-PROCESS'])
export type ParticipationStatus = z.infer<typeof ParticipationStatusEnum>

export const ParticipationRoleEnum = z.enum(['CHAIR', 'REQ-PARTICIPANT', 'OPT-PARTICIPANT', 'NON-PARTICIPANT'])
export type ParticipationRole = z.infer<typeof ParticipationRoleEnum>

export type Person = {
    name?: string
    email?: string
    dir?: string
}

export type Attendee = Person & {
    rsvp?: boolean
    partstat?: ParticipationStatus
    role?: ParticipationRole
}

export const AttendeeSchema = z.object({
    name: z.string().optional(),
    email: z.string().email().optional(),
    dir: z.string().optional(),
    rsvp: z.boolean().optional(),
    partstat: ParticipationStatusEnum.optional(),
    role: ParticipationRoleEnum.optional(),
    cuType: z.enum(['INDIVIDUAL', 'GROUP', 'RESOURCE', 'ROOM', 'UNKNOWN']).optional(),
    xNumGuests: z.number().optional(),
})

export const OrganizerSchema = z.object({
    email: z.string().email().optional(),
    name: z.string().optional(),
})

export const AlarmSchema = z.object({
    action: z.enum(['audio', 'display', 'email', 'procedure']),
    description: z.string().optional(),
    summary: z.string().optional(),
    duration: z.number().optional(), // ms
    trigger: z.number(), // positive for ms before event, negative for ms after event
    repeat: z.number().optional(),
    attachType: z.string().optional(),
    attach: z.string().optional(),
})

export type Alarm = z.infer<typeof AlarmSchema>

const CalItemSchema = z.object({
    calendarUrl: z.string(),
    etag: z.string().optional(),
    uid: z.string(),
})

export const ItemCalendarIdsSchema = z.object({
    id: z.string(),
    apple: CalItemSchema.optional(),
    google: CalItemSchema.optional(),
})

export type CalItem = z.infer<typeof CalItemSchema>
export type ItemCalendarIds = z.infer<typeof ItemCalendarIdsSchema>
