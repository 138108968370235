import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const flexFn = /* @__PURE__ */ createRecipe('flex', {}, [])

const flexVariantMap = {
  "direction": [
    "row",
    "column",
    "rowReverse",
    "columnReverse"
  ],
  "align": [
    "start",
    "center",
    "end",
    "stretch",
    "baseline"
  ],
  "justify": [
    "start",
    "center",
    "end",
    "between"
  ],
  "wrap": [
    "noWrap",
    "wrap",
    "wrapReverse"
  ],
  "gap": [
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9"
  ]
}

const flexVariantKeys = Object.keys(flexVariantMap)

export const flex = /* @__PURE__ */ Object.assign(memo(flexFn.recipeFn), {
  __recipe__: true,
  __name__: 'flex',
  __getCompoundVariantCss__: flexFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: flexVariantKeys,
  variantMap: flexVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, flexVariantKeys)
  },
  getVariantProps: flexFn.getVariantProps,
})