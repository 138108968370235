import { iconButton } from 'styled-system/recipes'
import { LessonConfig } from './types'
import { PlusIcon } from '@radix-ui/react-icons'
import Image from 'next/image'

const textButton = iconButton({ variant: 'ghost', size: 'auto' })
const AddSection = () => {
    return (
        <div className="flex flex-col gap-3">
            <br />
            <h4 className="text-color">
                Click on the{' '}
                <button className={textButton}>
                    <PlusIcon />
                </button>{' '}
                to add a category
            </h4>
            <Image alt="Where to add a new category" src={'/images/samples/folder.png'} width={450 / 2} height={70 / 2} />
            <br />
            <h4 className="text-color">
                Click on the{' '}
                <button className={textButton}>
                    <PlusIcon />
                    Add folder
                </button>{' '}
                to add a folder
            </h4>
            <Image alt="Where to add a new folder" src={'/images/samples/list-view-add-buttons.png'} width={464 / 2} height={440 / 2} />
            {/* TODO: record video of dragging */}
        </div>
    )
}

const CATEGORIES_CONFIG: LessonConfig = {
    title: 'Organize by Categories',
    description: 'Simple visualization method for managing tasks',
    visual: {
        type: 'carousel',
        title: "Here's what a typical Planda List View page may look like:",
        slides: [
            { src: '/images/samples/list-view-library.png', caption: 'Library theme' },
            { src: '/images/samples/list-view-library.png', caption: 'Library theme' },
        ],
    },
    featuresUnlocked: [],
    blocks: [{ type: 'custom', content: <AddSection /> }, 
        { 
        type: 'visual', 
        content: { 
            videoSrc: '/demos/dragndroptochangecat.mp4',
            type: 'video',
            width: 1920,
            height: 1080,
            maxWidth: 1000
        } 
        }
    ],
    challenges: [
        {
            text: 'Create a folder',
            id: 'category-units-create-folder',
            rewardAmount: 3,
            achievementsRequired: 'category-unit-create-section',
        },
        {
            text: 'Create a category',
            id: 'category-units-create-category',
            rewardAmount: 3,
            achievementsRequired: 'category-unit-create-category',
        },
        {
            text: "Drag a task into a category in planda.ca/list to change it's category",
            id: 'change-category-through-drag-list',
            rewardAmount: 2,
            achievementsRequired: 'change-category-through-drag-list',
        },
    ],
}

export default CATEGORIES_CONFIG
