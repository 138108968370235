import { PartialK } from '.'

export type New<T> = PartialK<T, 'createdAt' | 'updatedAt'>
export type NewPartialId<T> = PartialK<T, 'id' | 'createdAt' | 'updatedAt'>

export const toNewItem = <T>(item: T) => ({
    ...item,
    createdAt: Date.now(),
    updatedAt: Date.now(),
})
