import { LessonConfig } from "./types";

const QUOTE_SETTINGS_CONFIG: LessonConfig = {
    title: "Quote Settings",
    description: "Motivate yourself with quotes provided by planda",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['quote-settings'],
    challenges: [
        {
            text: 'Choose what type of quotes you preferre',
            id: 'quote-choice',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default QUOTE_SETTINGS_CONFIG
