import { LessonConfig } from "./types";

// https://www.linkedin.com/pulse/how-use-rule-three-master-productivity-j-d-meier/
const RULE_OF_THREE_CONFIG: LessonConfig = {
    title: "Rule of Three",
    description: "Be intentional. Simple, rule that takes less than 5 minutes per day. Help you get better results, from 'Getting Results the Agile Way' by J.D. Meier",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['rule-of-three'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default RULE_OF_THREE_CONFIG
