import { UserSchema, User } from '@/types'
import { UpdateItemParams } from 'dynamodb-helpers'
import { socialApiSlice } from './socialSlice'
import { ThemeCustomization } from '@/types/theme'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import { builderHelpers } from './builder'

const apiSlice = socialApiSlice.injectEndpoints({
    endpoints: (builder) => {
        const { builderArrayGET, builderArrayItemPUT, builderArrayItemPATCH } = builderHelpers(builder)

        return {
            getUser: builder.query<User, void>({
                query: () => `user`,
                providesTags(result, error, arg, meta) {
                    return [{ type: 'user' }]
                },
            }),
            patchUser: builder.mutation<void, UpdateItemParams<User>>({
                query: (updates) => {
                    const undefinedKeys = updates.set && Object.keys(updates.set).filter((key) => updates.set![key as keyof User] === undefined)
                    if (updates.set) updates.set = UserSchema.partial().parse(updates.set)
                    if (updates.remove && undefinedKeys) updates.remove = updates.remove.concat(undefinedKeys)
                    else updates.remove = undefinedKeys
                    return {
                        url: `user`,
                        method: 'PATCH',
                        body: { ...updates, remove: undefinedKeys },
                    }
                },
                invalidatesTags: (result, error, arg) => [{ type: 'user' }],
                async onQueryStarted(updates, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getUser', undefined, (data) => {
                            return { ...data, ...updates }
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            editNote: builder.mutation<void, { note: string; index: number }>({
                query: ({ note, index }) => {
                    return {
                        url: `user/notes`,
                        method: 'PATCH',
                        body: { note, index },
                    }
                },
                invalidatesTags: (result, error, arg) => [{ type: 'user' }],
                async onQueryStarted({ index, note }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getUser', undefined, (data) => {
                            data.notes[index] = note
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
            getGeneralThemeCustomizations: builder.query<ThemeCustomization, void>({
                query: () => 'user/theme/general',
                providesTags(result, error, arg, meta) {
                    return [{ type: 'theme-customizations', id: 'general' }]
                },
            }),
            updateGeneralThemeCustomizations: builder.mutation<ThemeCustomization, { updates: UpdateItemParams<ThemeCustomization> }>({
                query: ({ updates }) => {
                    return {
                        url: 'user/theme/general',
                        method: 'PATCH',
                        body: updates,
                    }
                },
                invalidatesTags: (result, error, arg, meta) => {
                    return [{ type: 'theme-customizations', id: 'general' }]
                },
                onQueryStarted: async ({ updates }, { dispatch, queryFulfilled }) => {
                    const patchedResult = dispatch(
                        apiSlice.util.updateQueryData('getGeneralThemeCustomizations', undefined, (data) => {
                            dynamodbUpdateItemOptimistic(data, updates)
                        })
                    )
                    queryFulfilled.catch(patchedResult.undo)
                },
            }),
        }
    },
})

export const userApiSlice = apiSlice
