import z from "src/lib/zod"
import { UserSchema } from "../user"


export const DBUserSchema = UserSchema.extend({
    userId: z.string(),
    id: z.string(), // `ACCOUNT#${userId}`
})

export type DBUser = z.infer<typeof DBUserSchema>
