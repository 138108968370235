import { LessonConfig } from './types'

const WEEK_LIST_PAGE_CONFIG: LessonConfig = {
    title: 'Week list page',
    description: 'See your tasks organized by day of week',
    visual: {
        type: 'image',
        imgSrc: '/example-pages/week-list.png',
        width: 1728,
        height: 813,
    },
    featuresUnlocked: ['week-list-page'],
    challenges: [
        {
            text: 'Drag a task from the "Drag into schedule" search bar to a day of the week',
            id: 'week-list-add-to-schedule-drag',
            times: 3,
            rewardAmount: 5,
            achievementsRequired: { key: 'week-list-add-to-schedule-drag', op: 'GEQ', value: 3 },
        },
        {
            text: 'Drag a bullet from one day to another',
            id: 'week-list-drag-item-bullet',
            rewardAmount: 2,
            achievementsRequired: 'week-list-drag-item-bullet',
        },
    ],
}

export default WEEK_LIST_PAGE_CONFIG
