import { LessonConfig } from "./types";

const SYLLABUS_PARSER_CONFIG: LessonConfig = {
    title: "Parse course syllabus",
    description: "Quickly set up your course by parsing your syllabus. We'll automatically add your assignments, grading distributions, exams, and more.",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['syllabus-parser'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default SYLLABUS_PARSER_CONFIG
