import { isPast, isFuture } from '@/utils/item';


export const TIME_PERIOD = [
    {
        title: 'Past',
        filter: isPast,
    },
    {
        title: 'Current',
        filter: (...params: Parameters<typeof isPast>) => !isPast(...params) && !isFuture(...params),
    },
    {
        title: 'Future',
        filter: isFuture,
    },
]
