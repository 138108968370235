import { Cost, Wallet } from "@/types/wallet";

export const balanceIsEnough = (wallet: Wallet | null | undefined, cost?: Cost) => {
    if (!cost) return true
    if (!wallet) return false
    return cost.currency === 'coins' ? wallet.balance >= cost.amount : wallet.gems >= cost.amount
}

export const deductCost = (wallet: Wallet, cost: Cost) => {
    if (cost.currency === 'coins') {
        return { ...wallet, balance: wallet.balance - cost.amount }
    } else {
        return { ...wallet, gems: wallet.gems - cost.amount }
    }
}
