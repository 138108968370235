import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const blobFn = /* @__PURE__ */ createRecipe('blob', {}, [])

const blobVariantMap = {}

const blobVariantKeys = Object.keys(blobVariantMap)

export const blob = /* @__PURE__ */ Object.assign(memo(blobFn.recipeFn), {
  __recipe__: true,
  __name__: 'blob',
  __getCompoundVariantCss__: blobFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: blobVariantKeys,
  variantMap: blobVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, blobVariantKeys)
  },
  getVariantProps: blobFn.getVariantProps,
})