import { LessonConfig } from "./types";

const BRAIN_DUMP_CONFIG: LessonConfig = {
    title: "Brain Dump",
    description: "Dump all tasks in your head down at once. Makes adding many tasks faster.",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['brain-dump'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default BRAIN_DUMP_CONFIG
