import { LessonConfig } from './types'
import Link from 'next/link'

// TODO: put mobile instructions here
const MOBILE_APP_CONFIG: LessonConfig = {
    title: 'Mobile App',
    description: 'Download Planda Mobile, and link it to this account',
    visual: {
        type: 'element',
        element: (
            <p>
                Go to <Link href={'/mobile'} />.
            </p>
        ),
    },
    featuresUnlocked: [],
    challenges: [
        {
            text: 'Get the mobile app',
            id: 'sync-mobile-app',
            rewardAmount: 50,
            achievementsRequired: 'sync-mobile-app',
        },
    ],
}

export default MOBILE_APP_CONFIG
