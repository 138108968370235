import { LessonConfig } from './types'

const DAILY_LIST_CONFIG: LessonConfig = {
    title: `Today's list`,
    description: `Fight decision paralysis with a focused list. Separate out today's tasks.`,
    visual: {
        type: 'carousel',
        slides: [
            { src: '/images/features/daily-list-today.png', caption: "Today's list" },
            { src: '/images/features/daily-list-cluttered.png', caption: 'Main list' },
        ],
    },
    blocks: [
        { type: 'bullets', content: ['Focus only on what matters', 'Reduce stress', 'Prevent feeling overwhelmed'] },
        {
            type: 'notes',
            content: `Q: How do you add a task to your Today's list?\nA: Drag it onto your calendar for today. Any task on your calendar for today will show up in the Today's list.`,
        },
        {
            type: 'visual',
            id: 'medthod 1',
            content: {
                type: 'video',
                videoSrc: '/demos/todays-list.mp4',
                width: 2876,
                height: 1302,
                maxWidth: 800,
            },
        },
        {
            type: 'notes',
            content: `Q: What if you don't know when to work on it yet?\nA: Click on the task on the planda.ca/list page to open up it's details, and click on "Add to today"`,
        },
        {
            type: 'visual',
            id: 'method 2',
            content: {
                type: 'image',
                imgSrc: 'add-to-today-info-bar.png',
                width: 628,
                height: 886,
                maxWidth: 300,
            },
        },
    ],
    featuresUnlocked: ['daily-list'],
    challenges: [
        {
            // this is a streak type challenge
            text: `Have at least 3 items in your Today's list`,
            id: 'daily-list-add-three',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
        {
            text: `Complete at least 3 items on your Today's list`,
            times: 3,
            id: 'daily-list-complete-three',
            rewardAmount: 30,
            achievementsRequired: { key: 'UNIMPLEMENTED', op: 'GEQ', value: 3 },
        },
    ],
}

export default DAILY_LIST_CONFIG
