import { CategoryRes, DatedItem, isDatedItem, Item, NewItem } from '@/types'
import { createNewItemPlaceholder, getTypeFromId } from '.'
import { UpdateItemParams } from 'dynamodb-helpers'
import { dynamodbUpdateItemOptimistic } from '../dynamodb'

export const putOptimisticItemGetCategoryResInPlace = (draft: CategoryRes, item: NewItem | Item) => {
    if (item.id) {
        const type = getTypeFromId(item.id)
        const index = draft.items[type].findIndex((x) => x.id === item.id)
        if (index === -1) {
            draft.items[item.type].push(
                // @ts-expect-error
                createNewItemPlaceholder(item as NewItem)
            )
        } else {
            draft.items[type][index] = item as Item
        }
    } else {
        draft.items[item.type].push(
            // @ts-expect-error
            createNewItemPlaceholder(item as NewItem)
        )
    }
}

export const putOptimisticItemGetDatedItemsInPlace = (draft: DatedItem[], item: NewItem) => {
    if (isDatedItem(item)) {
        const index = item.id ? draft.findIndex((x) => x.id === item.id) : -1
        if (index === -1) {
            draft.push(createNewItemPlaceholder(item) as DatedItem)
        } else {
            // TODO: make sure this doesn't break anything (worried about multi-line events + recurring)
            draft[index] = Object.assign(draft[index], item)
        }
    }
}

export const updateOptimisticGetCategoryResInPlace = (draft: CategoryRes, id: string, updates: UpdateItemParams) => {
    const type = getTypeFromId(id)
    // @ts-expect-error
    draft.items[type] = draft.items[type].map((x) => {
        // TODO: what about not updates.set?
        if (x.id !== id) return x
        return dynamodbUpdateItemOptimistic(x, updates)
    })
}
