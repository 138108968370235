import { z } from 'zod'

const BUILT_IN_KANBAN_STATUSES = {
    backlog: 'Backlog',
    todo: 'TODO',
    doing: 'In progress',
    done: 'Done',
}
type BuiltInKanbanStatus = keyof typeof BUILT_IN_KANBAN_STATUSES

export const KanbanStatusEnum = z.enum(Object.keys(BUILT_IN_KANBAN_STATUSES) as [BuiltInKanbanStatus, ...BuiltInKanbanStatus[]])

export const KanbanSettingsSchema = z.object({
    views: z.array(z.string()),
    customStatuses: z.record(
        z.object({
            name: z.string(),
        })
    ),
})

export type KanbanSettings = z.infer<typeof KanbanSettingsSchema>

export const DEFAULT_KANBAN_SETTINGS: KanbanSettings = {
    views: ['backlog', 'todo', 'doing', 'done'],
    customStatuses: {},
}

export const KANBAN_SETTINGS_CONFIG = {
    id: 'KANBAN_SETTINGS',
    Schema: KanbanSettingsSchema,
    TableName: process.env.TABLE_NAME!,
    defaultGetItem: DEFAULT_KANBAN_SETTINGS,
} /**
 * Tasks
 */

export type KanbanStatus = string
