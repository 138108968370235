import { LessonConfig } from "./types";

const ANIMATION_SETTINGS_CONFIG: LessonConfig = {
    title: "Animation Settings", // this should unlock animation store
    description: "Celebrate tasks completions with fun panda animations",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: [],
    challenges: [
        // {
        //     text: 'Disable animations',
        //     id: 'dis-animation',
        //     rewardAmount: 5,
        //     achievementsRequired: 'UNIMPLEMENTED',
        // },

        // {
        //     text: 'Adjust the chance of confetti',
        //     id: 'confetti-chance',
        //     rewardAmount: 5,
        //     achievementsRequired: 'UNIMPLEMENTED',
        // }
    ]
}

export default ANIMATION_SETTINGS_CONFIG
