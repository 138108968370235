import { compact, getSlotCompoundVariant, memo, splitProps } from '../helpers.mjs';
import { createRecipe } from './create-recipe.mjs';

const tableSlotsDefaultVariants = {
  "border": "solid"
}
const tableSlotsCompoundVariants = []

const tableSlotsSlotNames = [
  [
    "th",
    "table__th"
  ],
  [
    "td",
    "table__td"
  ],
  [
    "table",
    "table__table"
  ],
  [
    "thead",
    "table__thead"
  ],
  [
    "tr",
    "table__tr"
  ],
  [
    "tfoot",
    "table__tfoot"
  ],
  [
    "tbody",
    "table__tbody"
  ],
  [
    "caption",
    "table__caption"
  ]
]
const tableSlotsSlotFns = /* @__PURE__ */ tableSlotsSlotNames.map(([slotName, slotKey]) => [slotName, createRecipe(slotKey, tableSlotsDefaultVariants, getSlotCompoundVariant(tableSlotsCompoundVariants, slotName))])

const tableSlotsFn = memo((props = {}) => {
  return Object.fromEntries(tableSlotsSlotFns.map(([slotName, slotFn]) => [slotName, slotFn.recipeFn(props)]))
})

const tableSlotsVariantKeys = [
  "striped",
  "align",
  "border",
  "alignTh",
  "borderTh"
]
const getVariantProps = (variants) => ({ ...tableSlotsDefaultVariants, ...compact(variants) })

export const tableSlots = /* @__PURE__ */ Object.assign(tableSlotsFn, {
  __recipe__: false,
  __name__: 'tableSlots',
  raw: (props) => props,
  variantKeys: tableSlotsVariantKeys,
  variantMap: {
  "striped": [
    "true"
  ],
  "align": [
    "start",
    "center",
    "end"
  ],
  "border": [
    "solid",
    "dashed"
  ],
  "alignTh": [
    "start",
    "center",
    "end"
  ],
  "borderTh": [
    "solid",
    "dashed"
  ]
},
  splitVariantProps(props) {
    return splitProps(props, tableSlotsVariantKeys)
  },
  getVariantProps
})