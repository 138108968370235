export const MS_PER_DAY = 86400000
export const MS_PER_WEEK = MS_PER_DAY * 7;
export const MS_PER_HALF_HOUR = 1800000;
export const MS_PER_HOUR = MS_PER_HALF_HOUR * 2;
export const MS_PER_30_DAYS = 2592000000;
export const MS_PER_MINUTE = 60000;
export const MS_PER_15_MINUTE = Math.round(MS_PER_MINUTE * 15);
export const LOTS_OF_MS = 3000000000000;
export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday",]
export const FORMAT_DAY = 'MMM. d, y'
export const FORMAT_TIME = 'h:mm aaa'
export const DB_FORMAT_DAY = 'yyyy-MM-dd'