import { LessonConfig } from "./types";

const SUMMARY_TABLE_CONFIG: LessonConfig = {
    title: "Summary Table",
    description: "Future overview of tasks and important events",
    visual: {
        type: 'image',
        imgSrc: 'summary-table.png',
        imageTitle: 'Organize tasks into columns',
        width: 2296,
        height: 1592,
    },
    featuresUnlocked: ['summary-table'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default SUMMARY_TABLE_CONFIG
