import { z } from 'zod'
import { NewEvent, NewItem, NewTask } from './item'
import { endOfDay, startOfDay } from 'date-fns'
import { endOfDayInTimezone, startOfDayInTimezone } from '@planda/utils'
import { MS_PER_DAY, MS_PER_MINUTE } from '@constants/date'

/**
 * Today's items: are these just all-day taskEvents? but i don't want it to take up that much space on timegrid. maybe i should make the goal section related to this?
 * or is it just a list for each day?
 * -- today's items probably unnecessary feature for now
 */

const IMMUTABLE_USER_ATTRIBUTES = new Set(['userId', 'createdAt'])

export const SchoolEnum = z.enum([
    'High School',
    'University of British Columbia',
    // "University of Toronto",
    // "Simon Fraser University"
])

export type SchoolEnum = z.infer<typeof SchoolEnum>

export const DynamicWeekStartEnum = z.enum(['today', 'yesterday', 'none'])
export type DynamicWeekStartEnum = z.infer<typeof DynamicWeekStartEnum>

export const PluginEnum = z.enum(['alpha', 'beta', 'student', 'ubc', 'mobileApp'])
export type Plugin = z.infer<typeof PluginEnum>

export const UserSchema = z.object({
    createdAt: z.number(),
    updatedAt: z.number(),
    notes: z.array(z.string()).length(10),
    // groups: z.array(GroupSchema).optional(),
    numTasksCompleted: z.number(),
    numTasksCompletedThisWeek: z.number(), // resets every monday?
    weekStartsOn: z.number().min(0).max(6),
    dynamicWeekStart: DynamicWeekStartEnum.optional(), // "today", "yesterday"
    disableAnimations: z.boolean().optional(),
    confettiFrequency: z.number().optional(),
    level: z.number().min(1).default(1), // TODO: add level to each existing user programmatically
    plugins: z.preprocess((x) => (x instanceof Set ? [...x] : x), z.array(PluginEnum).optional()), // should be stored as a set
    school: SchoolEnum.optional(),
    mode: z.enum(['simplified', 'standard', 'premium']).optional(),
    style: z.enum(['plain', 'panda']).optional(),
})

export type User = z.infer<typeof UserSchema>

// UNUSED
export interface TodayItem {
    parentId: string
    name: string
    category: string
    [x: string]: string
}

interface Break {
    name: string
    start: Date
    end: Date
}

interface Term {
    name: string
    term: string
    termStart: Date
    teachingEnds: Date
    examsStart: Date
    examsEnd: Date
    teachingDays?: number
    breaks: Break[]
    events?: NewEvent[]
    tasks?: NewTask[]
}

// only terms[0] is used (current term)
export const schoolDefaults: Record<SchoolEnum, { timezone?: string; terms: Term[] }> = {
    [SchoolEnum.Enum['University of British Columbia']]: {
        timezone: 'America/Vancouver',
        terms: [
            {
                name: '2024W1',
                term: '1',
                termStart: startOfDayInTimezone('America/Vancouver', 2024, 8, 3),
                teachingEnds: endOfDayInTimezone('America/Vancouver', 2024, 11, 6), // needs to be endOfDay because of NewTemplateRecur cron dateEnd
                examsStart: startOfDayInTimezone('America/Vancouver', 2024, 11, 10),
                examsEnd: endOfDayInTimezone('America/Vancouver', 2024, 11, 21),
                teachingDays: 63,
                breaks: [
                    {
                        name: 'Mid-term break',
                        start: startOfDayInTimezone('America/Vancouver', 2024, 10, 11),
                        end: endOfDayInTimezone('America/Vancouver', 2024, 10, 13),
                    },
                ],
                events: [
                    {
                        category: 'external/ubc',
                        name: 'First Day of Classes',
                        dateStart: new Date(2024, 8, 4, 0, 0, 0, 0).getTime(),
                        dateEnd: new Date(2024, 8, 4, 23, 59, 59, 999).getTime(),
                        type: 'event',
                    },
                ],
                // tasks: [],
            },
            {
                // TODO
                name: '2024W2',
                term: '2',
                termStart: startOfDayInTimezone('America/Vancouver', 2025, 0, 6),
                teachingEnds: endOfDayInTimezone('America/Vancouver', 2025, 3, 8), // needs to be endOfDay because of NewTemplateRecur cron dateEnd
                examsStart: startOfDayInTimezone('America/Vancouver', 2025, 3, 12),
                examsEnd: endOfDayInTimezone('America/Vancouver', 2025, 3, 27),
                teachingDays: 62,
                breaks: [
                    {
                        name: 'Mid-term break',
                        start: startOfDayInTimezone('America/Vancouver', 2025, 1, 17),
                        end: endOfDayInTimezone('America/Vancouver', 2025, 1, 21),
                    },
                ],
                // events: [{
                //     category: 'external/ubc',
                //     name: "First Day of Classes",
                //     dateStart: new Date(2021, 8, 7, 0, 0, 0, 0),
                //     dateEnd: new Date(2021, 8, 7, 23, 59, 59, 999),
                // }],
                // tasks: [],
            },
        ],
    },
    [SchoolEnum.Enum['High School']]: { terms: [] },
    // {
    //     termStarts: [new Date(2023, 8, 5), new Date(2024, 0, 8)],
    //     teachingEnds: [new Date(2023, 11, 7), new Date(2024, 3, 12)],
    //     events: [{
    //         name: "First Day of Classes",
    //         dateStart: new Date(2021, 8, 7, 0, 0, 0, 0),
    //         dateEnd: new Date(2021, 8, 7, 23, 59, 59, 999),
    //     }]
    // }
}

export const isSchool = (school: unknown): school is SchoolEnum => {
    return SchoolEnum.safeParse(school).success
}

export const getTerm = (school: SchoolEnum) => {
    if (schoolDefaults[school].terms.length === 0) return null
    return schoolDefaults[school].terms[0]
}

const SCHOOL_DAY_DURATION_MS = MS_PER_DAY - MS_PER_MINUTE
export const importSchoolDefaults = (school: SchoolEnum) => {
    if (schoolDefaults[school].terms.length === 0) return null
    const term = schoolDefaults[school].terms[0]
    const schoolId = school.valueOf().replace(/ /g, '_')
    const idOf = (termName: string, name: string, type: string) => `i.${type}.` + (schoolId + '=' + termName + '=' + name).replace(/ /g, '_')
    // should be able to set IDs, in case people import twice
    const events: NewEvent[] = [
        {
            name: 'First Day of Classes',
            id: idOf(term.name, 'termStart', 'event'),
            dateStart: term.termStart.getTime(),
            // TODO: this happens in UTC (since serverside, need to pass time-zone in)
            dateEnd: term.termStart.getTime() + SCHOOL_DAY_DURATION_MS,
            category: 'school/' + schoolId,
            type: 'event',
        },
        {
            name: 'Last Day of Classes',
            id: idOf(term.name, 'teachingEnds', 'event'),
            dateStart: term.teachingEnds.getTime() - SCHOOL_DAY_DURATION_MS,
            dateEnd: term.teachingEnds.getTime(),
            category: 'school/' + schoolId,
            type: 'event',
        },
        {
            name: 'Exams Start',
            id: idOf(term.name, 'examsStart', 'event'),
            dateStart: term.examsStart.getTime(),
            dateEnd: term.examsStart.getTime() + SCHOOL_DAY_DURATION_MS,
            category: 'school/' + schoolId,
            type: 'event',
        },
    ]

    term.breaks.forEach((break_) => {
        events.push({
            name: break_.name,
            dateStart: startOfDay(break_.start).getTime(),
            dateEnd: endOfDay(break_.end).getTime(),
            category: 'school/' + schoolId,
            type: 'event',
        })
    })

    const applyIds = <T extends NewItem>(items: T[]) => items.map((item) => ({ id: idOf(term.name, item.name, item.type), ...item }))

    return {
        items: applyIds(events),
    }
}

export const StreakSchema = z.object({
    id: z.literal('STREAK_LOGIN'),
    current: z.number(),
    highest: z.number(),
    lastLogin: z.number(),
})

export type Streak = z.infer<typeof StreakSchema>

export const DEFAULT_STREAK: Streak = { id: 'STREAK_LOGIN', current: 0, lastLogin: 0, highest: 0 }
