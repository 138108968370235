import { iconButton } from 'styled-system/recipes'
import { LessonConfig } from './types'
import { HamburgerMenuIcon } from '@radix-ui/react-icons'

const EXTERNAL_CALENDAR_SYNC_CONFIG: LessonConfig = {
    title: 'Google Calendar, Apple Calendar, etc.',
    description: 'Sync your Planda calendar with any of our supported calendars',
    visual: {
        type: 'element',
        element: (
            <p>
                Click on{' '}
                <button className={iconButton({ variant: 'ghost' })}>
                    <HamburgerMenuIcon />
                </button>{' '}
                in the top left corner and select &quot;Google Calendar&quot; or &quot;Apple Calendar&quot; to get started.
            </p>
        ),
    },
    blocks: [{ type: 'bullets', content: ['Google Calendar', 'Apple Calendar'] }],
    featuresUnlocked: [],
    challenges: [
        {
            text: 'Sync with an external calendar (Google, Apple, etc.)',
            id: 'sync-with-external-calendar',
            rewardAmount: 10,
            achievementsRequired: 'sync-with-external-calendar',
        },
    ],
}

export default EXTERNAL_CALENDAR_SYNC_CONFIG
