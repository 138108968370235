import { TIME_PERIOD } from '@/app/(auth)/list/components/tasks-display/constants'
import { CategoryUnits, CategoryUnitsSchema, Event, ItemsByType, ItemsByTypeOffline, ItemsByTypeOfflineSchema, Task } from '@/types'
import { toNewItem } from '@/types/newItem'
import { MS_PER_DAY } from '@planda/utils'
import { areIntervalsOverlapping } from 'date-fns'
import localforage from 'localforage'

export const updateOfflinePlannerItems = (items_: ItemsByType) => {
    console.log('saving to offline storage...')
    const allItems = { ...items_ }
    allItems.event = allItems.event.filter((event) =>
        areIntervalsOverlapping(
            {
                start: event.dateStart,
                end: event.dateEnd,
            },
            {
                start: Date.now(),
                end: Date.now() + MS_PER_DAY * 2,
            }
        )
    )
    allItems.task = allItems.task.filter(TIME_PERIOD[1].filter)
    const items = ItemsByTypeOfflineSchema.parse(allItems)
    // TODO: not sure if this will parse since nested
    localforage.setItem('planner-items-category-res', items)
}

export const getOfflineItems = () => {}

export const transformPlannerItems = async (items: ItemsByType | undefined, isOffline: boolean) => {
    const offlinePlannerItems = ItemsByTypeOfflineSchema.parse(await localforage.getItem('planner-items-category-res'))
    if (!items) return offlinePlannerItems && offlineItemsToRegular(offlinePlannerItems)
    if (isOffline && offlinePlannerItems) {
        const items = offlineItemsToRegular(offlinePlannerItems)
        for (const t in items) {
            const type = t as keyof ItemsByType
            if (items[type].length) {
                // @ts-ignore
                items[type] = items[type]
            }
        }
        return items
    }
    return items
}

const offlineItemsToRegular = (offline: ItemsByTypeOffline): ItemsByType => {
    return {
        ...offline,
        task: offline.task.map((task) => {
            const item: Task = toNewItem({ ...task, type: 'task' })
            return item
        }),
        event: offline.event.map((event) => {
            const item: Event = toNewItem({ ...event, type: 'event' })
            return item
        }),
    }
}

export const getOfflineCategories = async () => {
    const res = await localforage.getItem('categories')
    if (CategoryUnitsSchema.safeParse(res).success) {
        return res as CategoryUnits
    }
    return null
}
