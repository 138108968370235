import { mapObject } from '@planda/utils'

export const defaultCategoryColour = {
    defaulttxt: '$gray12',

    categorybg1: '$secondary8',
    categorytxt1: '$defaulttxt',

    categorybg2: '$secondary7',
    categorytxt2: '$defaulttxt',

    categorybg3: '$secondary6',
    categorytxt3: '$defaulttxt',

    categorybg4: '$secondary5',
    categorytxt4: '$defaulttxt',

    categorybg5: '$secondary4',
    categorytxt8: '$defaulttxt',

    categorybg6: '$primary5',
    categorytxt5: '$defaulttxt',

    categorybg7: '$primary6',
    categorytxt6: '$defaulttxt',

    categorybg8: '$primary7',
    categorytxt7: '$defaulttxt',
}

export const categoryColor = (typeAndNum: string | number | undefined) => {
    // should be either 'task' or 1 // 1-indexed
    if (!typeAndNum) return {}
    if (typeof typeAndNum === 'number')
        return {
            color: '$categorytxt' + typeAndNum,
            backgroundColor: '$categorybg' + typeAndNum,
        }
    const vals = typeAndNum.split(' ')
    for (let val of vals) {
        const num = parseInt(val)
        if (!Number.isNaN(num))
            return {
                color: '$categorytxt' + num,
                backgroundColor: '$categorybg' + num,
            }
    }
    if (vals.includes('task'))
        return {
            color: '$tasktxt',
            backgroundColor: '$taskbg',
        }
    if (vals.includes('event') || vals.includes('templateRecur'))
        return {
            color: '$eventtxt',
            backgroundColor: '$eventbg',
        }
    return {}
}

export const CATEGORIES1 = {
    categorybg1: '#dccef2',
    categorytxt1: '{colors.$hiContrast}',

    categorybg2: '#729ea1',
    categorytxt2: '{colors.$loContrast}',

    categorybg3: '#b5bd89',
    categorytxt3: '{colors.$loContrast}',

    categorybg4: '#ebceab',
    categorytxt4: '{colors.$hiContrast}',

    categorybg5: '#adcad6',
    categorytxt5: '{colors.$loContrast}',

    categorybg6: '#e0b0D5',
    categorytxt6: '{colors.$loContrast}',

    categorybg7: '#ad7a99',
    categorytxt7: '{colors.$loContrast}',

    categorybg8: '#6dc0d5',
    categorytxt8: '{colors.$loContrast}',
}

export const CATEGORY_COLORS = mapObject(defaultCategoryColour, (value) => `{colors.${value}}`)
