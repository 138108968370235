import { memo, splitProps } from '../helpers.mjs';
import { createRecipe, mergeRecipes } from './create-recipe.mjs';

const buttonFn = /* @__PURE__ */ createRecipe('ds-button', {
  "size": "1",
  "variant": "gray"
}, [
  {
    "variant": "gray",
    "ghost": true,
    "css": {
      "backgroundColor": "transparent",
      "color": "$hiContrast",
      "_hover": {
        "&:hover": {
          "backgroundColor": "$grayA3",
          "boxShadow": "none"
        }
      },
      "&:active": {
        "backgroundColor": "$grayA4"
      },
      "&:focus": {
        "boxShadow": "inset 0 0 0 1px {colors.$grayA8}, 0 0 0 1px {colors.$grayA8}"
      },
      "&[data-radix-popover-trigger][data-state=\"open\"], &[data-radix-dropdown-menu-trigger][data-state=\"open\"]": {
        "backgroundColor": "$grayA4",
        "boxShadow": "none"
      }
    }
  },
  {
    "variant": "blue",
    "ghost": true,
    "css": {
      "backgroundColor": "transparent",
      "_hover": {
        "&:hover": {
          "backgroundColor": "$infoA3",
          "boxShadow": "none"
        }
      },
      "&:active": {
        "backgroundColor": "$infoA4"
      },
      "&:focus": {
        "boxShadow": "inset 0 0 0 1px {colors.$infoA8}, 0 0 0 1px {colors.$infoA8}"
      },
      "&[data-radix-popover-trigger][data-state=\"open\"], &[data-radix-dropdown-menu-trigger][data-state=\"open\"]": {
        "backgroundColor": "$infoA4",
        "boxShadow": "none"
      }
    }
  },
  {
    "variant": "green",
    "ghost": true,
    "css": {
      "backgroundColor": "transparent",
      "_hover": {
        "&:hover": {
          "backgroundColor": "$successA3",
          "boxShadow": "none"
        }
      },
      "&:active": {
        "backgroundColor": "$successA4"
      },
      "&:focus": {
        "boxShadow": "inset 0 0 0 1px {colors.$successA8}, 0 0 0 1px {colors.$successA8}"
      },
      "&[data-radix-popover-trigger][data-state=\"open\"], &[data-radix-dropdown-menu-trigger][data-state=\"open\"]": {
        "backgroundColor": "$successA4",
        "boxShadow": "none"
      }
    }
  },
  {
    "variant": "red",
    "ghost": true,
    "css": {
      "backgroundColor": "transparent",
      "_hover": {
        "&:hover": {
          "backgroundColor": "$errorA3",
          "boxShadow": "none"
        }
      },
      "&:active": {
        "backgroundColor": "$errorA4"
      },
      "&:focus": {
        "boxShadow": "inset 0 0 0 1px {colors.$errorA8}, 0 0 0 1px {colors.$errorA8}"
      },
      "&[data-radix-popover-trigger][data-state=\"open\"], &[data-radix-dropdown-menu-trigger][data-state=\"open\"]": {
        "backgroundColor": "$errorA4",
        "boxShadow": "none"
      }
    }
  }
])

const buttonVariantMap = {
  "size": [
    "1",
    "2",
    "3"
  ],
  "variant": [
    "primary",
    "gray",
    "blue",
    "green",
    "red",
    "transparentWhite",
    "transparentBlack"
  ],
  "state": [
    "active",
    "waiting"
  ],
  "ghost": [
    "true"
  ]
}

const buttonVariantKeys = Object.keys(buttonVariantMap)

export const button = /* @__PURE__ */ Object.assign(memo(buttonFn.recipeFn), {
  __recipe__: true,
  __name__: 'button',
  __getCompoundVariantCss__: buttonFn.__getCompoundVariantCss__,
  raw: (props) => props,
  variantKeys: buttonVariantKeys,
  variantMap: buttonVariantMap,
  merge(recipe) {
    return mergeRecipes(this, recipe)
  },
  splitVariantProps(props) {
    return splitProps(props, buttonVariantKeys)
  },
  getVariantProps: buttonFn.getVariantProps,
})