import { LessonConfig } from "./types"
import { css, cva } from "styled-system/css"

const Th = ({ children }: { children: React.ReactNode }) => <th className={css({ textAlign: "center", fontWeight: 'bold', color: '$primary11', padding: '$1', fontSize: '1.1em' })}>{children}</th>

const list = css({
    display: "flex",
    flexDirection: "column",
    gap: 10,
    padding: 15
})

const blob = cva({
    variants: {
        type: {
            event: {
                border: "1px solid {colors.$success11}",
                background: "$success3"
            },
            task: {
                border: "1px solid {colors.$info11}",
                background: "$info3"
            }
        }
    },
    base: {
        borderRadius: 5,
        padding: 5
    }
})
const EventBlobs = [
    'Has specific timing',
    'Ex. Attend meeting tomorrow from 4pm-5pm'
]
const TaskBlobs = [
    'Has flexible timing: work on anytime between now and when it is due',
    'Ex. Project due next week.'
]

const TaskEventComparisonTable = () => <table className={css({
    display: "grid",
    background: "$text",
    gap: 1,
    gridTemplateColumns: "1fr 1fr",
    width: 450,
    "& > *": {
        backgroundColor: "$primary2"
    }
})}>
    <Th>Event</Th>
    <Th>Task</Th>
    <div className={list}>
        {
            EventBlobs.map(text => <div className={blob({ type: 'event' })} key={text}>{text}</div>)
        }
    </div>
    <div className={list}>
        {
            TaskBlobs.map(text => <div className={blob({ type: 'task' })} key={text}>{text}</div>)
        }
    </div>
</table>

const TASKS_EVENTS_CONFIG: LessonConfig = {
    title: "Tasks vs Events",
    description: "Know the difference",
    visual: {
        type: 'element',
        element: <TaskEventComparisonTable />,
    },
    challenges: [
        {
            text: 'Create a task',
            id: 'create-task',
            achievementsRequired: { op: 'OR', valid: ['create-task-with-nlp', 'create-task-with-form'] },
            rewardAmount: 3,
        },
        {
            text: 'Create an event',
            id: 'create-event',
            achievementsRequired: { op: 'OR', valid: ['create-event-with-nlp', 'create-event-with-form'] },
            rewardAmount: 3,
        },
        {
            text: "Drag a task into your calendar",
            id: 'drag-task-into-calendar',
            achievementsRequired: 'drag-task-into-calendar',
            rewardAmount: 2,
        },
        {
            text: "Resize an event to change its duration",
            id: 'event-resize',
            achievementsRequired: 'calendar-event-resize',
            rewardAmount: 2,
        },
    ],
}

export default TASKS_EVENTS_CONFIG
