import { LessonConfig } from "./types";

const DYNAMIC_WEEK_START_CONFIG: LessonConfig = {
    title: "",
    description: "",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['dynamic-week-start'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default DYNAMIC_WEEK_START_CONFIG
