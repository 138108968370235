import { addDays, addWeeks, endOfDay, isSameDay, nextDay, previousDay, startOfDay } from 'date-fns'
import { DAYS_OF_WEEK } from '@constants/date'
import { parseDate } from 'chrono-node'
import { castDayOfWeek } from '@planda/utils'

const testStart = (input: string, prefix: string) => input.toLowerCase().startsWith(prefix)
export const parseFromDateString = (input: string) => {
    if (testStart(input, 'tod')) {
        return endOfDay(new Date())
    }
    if (testStart(input, 'tom')) {
        return endOfDay(addDays(new Date(), 1))
    }
    if (testStart(input, 'yes')) {
        return endOfDay(addDays(new Date(), -1))
    }
    if (testStart(input, 'next week')) {
        return endOfDay(addWeeks(new Date(), 1))
    }
    if (testStart(input, 'last week')) {
        return endOfDay(addWeeks(new Date(), -1))
    }
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
        if (testStart(input, 'next ' + DAYS_OF_WEEK[i].slice(0, 3).toLowerCase())) {
            return endOfDay(nextDay(new Date(), castDayOfWeek(i % 7)))
        }
    }
    for (let i = 0; i < DAYS_OF_WEEK.length; i++) {
        if (testStart(input, 'last ' + DAYS_OF_WEEK[i].slice(0, 3).toLowerCase())) {
            return endOfDay(previousDay(new Date(), castDayOfWeek(i % 7)))
        }
    }

    return parseDate(input)
}

export const daysInRange = (startedTrackingOn: number, end = Date.now()) => {
    const start = new Date(startedTrackingOn)
    const days: number[] = []
    while (start.getTime() < end && !isSameDay(start, end)) {
        days.push(startOfDay(start).getTime())
        start.setDate(start.getDate() + 1)
    }
    if (isSameDay(start, end)) {
        days.push(startOfDay(start).getTime())
    }
    return days
}
