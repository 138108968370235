import { LessonConfig } from "./types";

const STUDENT_GRADES_CONFIG: LessonConfig = {
    title: "Grade Tracker",
    description: "",
    visual: {
        type: 'image',
        imgSrc: 'coming-soon.jpg',
        width: 1280,
        height: 854,
    },
    featuresUnlocked: ['student-grades'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default STUDENT_GRADES_CONFIG
