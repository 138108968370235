import { LessonConfig } from "./types";

const TIMEGRID_PANEL_CONFIG: LessonConfig = {
    title: "Calendar (day) Panel",
    description: "Open up your weekly/daily calendar anywhere",
    visual: {
        type: 'carousel',
        slides: [
            {
                src: '/images/features/drag-task-into-timegrid.png',
                caption: 'Drag task into calendar'
            }
        ]
    },
    featuresUnlocked: ['timegrid-panel'],
    challenges: [
        {
            text: '',
            id: '',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ]
}

export default TIMEGRID_PANEL_CONFIG
