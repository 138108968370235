import { z } from 'zod'
import ANIMATION_SETTINGS_CONFIG from './animationSettings'
import BETA_SETTINGS_CONFIG from './betaSettings'
import CATEGORIES_CONFIG from './categories'
import DAILY_LIST_CONFIG from './dailyList'
import DAYGRID_PANEL_CONFIG from './daygridPanel'
import DYNAMIC_WEEK_START_CONFIG from './dynamicWeekStart'
import GOALS_CONFIG from './goals'
import KANBAN_CONFIG from './kanban'
import PRIORITY_SQUARE_CONFIG from './priorityTable'
import QUOTE_SETTINGS_CONFIG from './quoteSettings'
import STUDENT_GRADES_CONFIG from './studentGrades'
import SUBTASKS_CONFIG from './subtasks'
import SUMMARY_TABLE_CONFIG from './summaryTable'
import SYLLABUS_PARSER_CONFIG from './syllabusParser'
import TASKS_EVENTS_CONFIG from './tasksEvents'
import TEMPLATE_DB_TASK_CONFIG from './templateDBTask'
import TIMEGRID_PANEL_CONFIG from './timegridPanel'
import WEEK_LIST_PAGE_CONFIG from './weekListPage'
import WELCOME_SCAVENGER_HUNT_CONFIG from './welcomeScavengerHunt'
import EXTERNAL_CALENDAR_SYNC_CONFIG from './externalCalendarSync'
import MOBILE_APP_CONFIG from './mobileApp'
import BRAIN_DUMP_CONFIG from './brainDump'
import RULE_OF_THREE_CONFIG from './ruleOfThree'
import HABIT_TRACKER_CONFIG from './habitTracker'
import COMPLETED_TASKS_VIEW_CONFIG from './completedTasksView'

export const ALL_LESSONS = {
    kanban: KANBAN_CONFIG,
    tasksVersesEvents: TASKS_EVENTS_CONFIG,
    categoriesIntro: CATEGORIES_CONFIG,
    'daily-list': DAILY_LIST_CONFIG,
    welcomeScavengerHunt: WELCOME_SCAVENGER_HUNT_CONFIG,
    'priority-square': PRIORITY_SQUARE_CONFIG,
    'summary-table': SUMMARY_TABLE_CONFIG,
    'week-list-page': WEEK_LIST_PAGE_CONFIG,
    'i.templateDB.task': TEMPLATE_DB_TASK_CONFIG,
    'student-grades': STUDENT_GRADES_CONFIG,
    'timegrid-panel': TIMEGRID_PANEL_CONFIG,
    'daygrid-panel': DAYGRID_PANEL_CONFIG,
    'animation-settings': ANIMATION_SETTINGS_CONFIG,
    'dynamic-week-start': DYNAMIC_WEEK_START_CONFIG,
    'quote-settings': QUOTE_SETTINGS_CONFIG,
    'beta-settings': BETA_SETTINGS_CONFIG,
    subtasks: SUBTASKS_CONFIG,
    goals: GOALS_CONFIG,
    'syllabus-parser': SYLLABUS_PARSER_CONFIG,
    'external-calendar-sync': EXTERNAL_CALENDAR_SYNC_CONFIG,
    'mobile-app': MOBILE_APP_CONFIG,
    'brain-dump': BRAIN_DUMP_CONFIG,
    'rule-of-three': RULE_OF_THREE_CONFIG,
    'habit-tracker': HABIT_TRACKER_CONFIG,
    'completed-tasks-view': COMPLETED_TASKS_VIEW_CONFIG,
} as const

Object.freeze(ALL_LESSONS)

export type LessonId = keyof typeof ALL_LESSONS

export const isLessonId = (id: string): id is LessonId => {
    return id in ALL_LESSONS
}

const LessonIds = Object.keys(ALL_LESSONS) as [LessonId, ...LessonId[]]

export const LessonIdEnum = z.enum(LessonIds)

export const TUTORIAL_LESSON_IDS: Readonly<LessonId[]> = ['tasksVersesEvents', 'categoriesIntro']
Object.freeze(TUTORIAL_LESSON_IDS)

const BONUS_LESSONS: Readonly<LessonId[]> = ['syllabus-parser', 'timegrid-panel', 'animation-settings', 'quote-settings', 'daygrid-panel']
const TECHNIQUE_LESSONS: Readonly<LessonId[]> = ['rule-of-three']

export const AVAILABLE_LESSON_IDS: Readonly<LessonId[]> = [
    'subtasks',
    'priority-square',
    'kanban',
    'i.templateDB.task',
    'week-list-page',
    'daily-list',
    // 'goals',
]
Object.freeze(AVAILABLE_LESSON_IDS)

export const PERMANENT_LESSONS: Readonly<LessonId[]> = ['external-calendar-sync', 'mobile-app']
Object.freeze(PERMANENT_LESSONS)
