import { LessonConfig } from './types'

const TEMPLATE_DB_TASK_CONFIG: LessonConfig = {
    title: 'Schedule Tasks',
    description: 'Auto create tasks on schedule, and create templates for easy task creation',
    costToUnlock: { amount: 20, currency: 'coins' },
    blocks: [
        { 
            type: 'visual', 
            content: { 
                videoSrc: '/demos/Template task 1.mp4',
                type: 'video',
                width: 1920,
                height: 1080,
                maxWidth: 1000
            } 
        },
        {
            type: 'notes',
            content: 'You can create a scheduled task using the template function when adding/creating new tasks. This will allow you to calibrate the due dates of the reoccurring tasks easily'
        },
    ],
    featuresUnlocked: ['i.templateDB.task'],
    challenges: [
        {
            text: 'Create a template',
            id: 'task-auto-add',
            rewardAmount: 3,
            achievementsRequired: 'create-templateDB.task-with-form',
        },
        {
            text: 'This challenge will be completed when 3 tasks are added to your calendar on a schedule.',
            id: 'interval-task',
            rewardAmount: 6,
            times: 3,
            achievementsRequired: {
                op: 'GEQ',
                key: 'task-auto-added-on-schedule',
                value: 3,
            },
        },
        {
            text: 'This challenge will be completed when 3 tasks are added from a template are completed',
            id: 'interval-task',
            rewardAmount: 30,
            times: 3,
            achievementsRequired: {
                op: 'GEQ',
                key: 'template-made-task-completed',
                value: 3,
            },
        },
    ],
}

export default TEMPLATE_DB_TASK_CONFIG
