import { LessonConfig } from './types'

const COMPLETED_TASKS_VIEW_CONFIG: LessonConfig = {
    title: 'Achievements',
    description: `Provides a tangible sense of accomplishment. Know what you've been working on, when.`,
    // visual: {
    //     type: 'image',
    //     imgSrc: 'coming-soon.jpg',
    //     width: 1280,
    //     height: 854,
    // },
    costToUnlock: {
        currency: 'coins',
        amount: 5,
    },
    coverImgSrc: 'completed-tasks-view.png',
    blocks: [
        {
            type: 'visual',
            content: {
                imgSrc: 'completed-tasks-view.png',
                type: 'image',
                width: 2288,
                height: 1282,
                maxWidth: 1000,
            },
        },
        {
            type: 'bullets',
            content: [
                'Boost confidence and motivation, which leads to greater success',
                `Reviewing past achievements lets you see how far you've come`,
                `Encourages positive habits and reinforces a positive mindset`
            ],
        },
    ],
    featuresUnlocked: ['completed-tasks-view'],
    challenges: [],
}

export default COMPLETED_TASKS_VIEW_CONFIG
