import { LessonConfig } from './types'

const SUBTASKS_CONFIG: LessonConfig = {
    title: 'Subtasks (Salami Slicing)',
    description: 'Slice your complex task into smaller, less daunting subtasks —like slicing a salami',
    coverImgSrc: 'subtasks.png',
    blocks: [
        {
            type: 'visual',
            content: {
                type: 'image',
                imgSrc: 'subtasks.png', // can also do salami.jpg
                width: 408,
                height: 366,
                maxWidth: 358,
            },
        },
        {
            type: 'bullets',
            content: [
                'Slice tasks into easy under 30 minute subtasks.',
                'You can pair this with the pomodoro technique complete one task per work interval.',
                'Have an easier time getting started, and celebrate small wins!',
            ],
        },
    ],
    featuresUnlocked: ['subtasks'],
    challenges: [
        {
            text: 'Split a task into a minimum of 2 subtasks',
            id: 'split-into-min-2-subtasks',
            times: 2,
            rewardAmount: 8,
            achievementsRequired: { key: 'split-into-min-2-subtasks', op: 'GEQ', value: 2 },
        },
        {
            text: 'Complete a subtask',
            id: 'complete-subtask',
            times: 3,
            rewardAmount: 30,
            achievementsRequired: { key: 'complete-subtask', op: 'GEQ', value: 3 },
        },
    ],
}

export default SUBTASKS_CONFIG
