import { AppleCalendarCredentials, CalendarId, GoogleCalendarCredentials, GoogleCalendarId } from '@/services/external/types'
import { gameApiSlice } from './gameSlice'
import { ThunkDispatch, UnknownAction } from '@reduxjs/toolkit'

const settingsUpdateOnQueryStarted =
    (calendarId: CalendarId, endPoint: 'getGoogleCalendarSettings' | 'getAppleCalendarSettings') =>
    (updates: any, { dispatch, queryFulfilled }: { dispatch: ThunkDispatch<any, any, UnknownAction>; queryFulfilled: any }) => {
        const patchedResults = [
            dispatch(
                integrationSlice.util.updateQueryData(endPoint, undefined, (data) => {
                    return { ...data, ...updates }
                })
            ),
            'disabled' in updates &&
                dispatch(integrationSlice.util.updateQueryData('externalCalendarIsEnabled', calendarId, () => !updates.disabled)),
        ]
        queryFulfilled.catch(() => {
            patchedResults.forEach((patchedResult) => patchedResult && patchedResult.undo())
        })
    }

const updateCalendarQuery = (calendarId: CalendarId) => (updates: Record<string, any>) => {
    const remove = Object.keys(updates).filter((key) => updates[key] === undefined)
    return {
        url: `external/calendar/${calendarId}/settings`,
        method: 'PATCH',
        body: { set: updates, remove },
    }
}

export const integrationSlice = gameApiSlice.injectEndpoints({
    endpoints: (builder) => {
        return {
            resetExternalCalendarSync: builder.mutation<void, CalendarId>({
                query: (calendarId) => ({
                    url: `external/calendar/${calendarId}/debug`,
                    method: 'DELETE',
                }),
            }),
            externalCalendarIsEnabled: builder.query<boolean, CalendarId>({
                query: (calendarId) => `external/calendar/${calendarId}`,
                transformResponse(baseQueryReturnValue, meta, arg) {
                    // @ts-ignore
                    return !!baseQueryReturnValue?.isEnabled
                },
            }),
            disableExternalCalendar: builder.mutation<void, CalendarId>({
                query: (calendarId) => ({
                    url: `external/calendar/${calendarId}`,
                    method: 'DELETE',
                }),
                async onQueryStarted(calendarId, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(integrationSlice.util.updateQueryData('externalCalendarIsEnabled', calendarId, () => false))
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                    })
                },
            }),
            enableAppleCalendar: builder.mutation<void, CalendarId>({
                query: (calendarId) => ({
                    url: `external/calendar/${calendarId}`,
                    method: 'DELETE',
                }),
                async onQueryStarted(calendarId, { dispatch, queryFulfilled }) {
                    const patchedResults = [dispatch(integrationSlice.util.updateQueryData('externalCalendarIsEnabled', calendarId, () => true))]
                    queryFulfilled.catch(() => {
                        patchedResults.forEach((patchedResult) => patchedResult.undo())
                    })
                },
            }),
            getGoogleCalendarSettings: builder.query<GoogleCalendarCredentials & { calendars: GoogleCalendarId[] }, void>({
                query: () => 'external/calendar/google/settings',
            }),
            updateGoogleCalendarSettings: builder.mutation<void, Partial<GoogleCalendarCredentials>>({
                query: updateCalendarQuery('google'),
                onQueryStarted: settingsUpdateOnQueryStarted('google', 'getGoogleCalendarSettings'),
            }),
            getAppleCalendarSettings: builder.query<AppleCalendarCredentials, void>({
                query: () => 'external/calendar/apple/settings',
            }),
            updateAppleCalendarSettings: builder.mutation<void, Partial<AppleCalendarCredentials>>({
                query: updateCalendarQuery('apple'),
                onQueryStarted: settingsUpdateOnQueryStarted('apple', 'getAppleCalendarSettings'),
            }),
        }
    },
})
