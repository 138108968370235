import { getCategoryNumGlobal, pathToNamesArrayGlobal } from '../categories'
import { CategorySchema, SectionSchema, CategoryRes, getEmptyCategoryRes, Item, CategoryUnits } from '@/types'

export const parseUpdates = (path: string, properties: { [x: string]: any }) => {
    const [sectionId, categoryId] = path.split('/')
    const schema = (categoryId ? CategorySchema : SectionSchema).partial()
    const updates = schema.parse(properties)
    return updates
}
export const getCategoryOptimisticData = (path: string, properties: { [x: string]: any }, x: CategoryRes | undefined) => {
    const [sectionId, categoryId] = path.split('/')
    const updates = parseUpdates(path, properties)
    if (!x) return getEmptyCategoryRes()
    if (sectionId) {
        x.categoryUnits.categories[sectionId] = x.categoryUnits.categories[sectionId].map((category) => {
            if (category.id !== path) return category
            return { ...category, ...updates }
        })
    } else {
        x.categoryUnits.sections = x.categoryUnits.sections.map((section) => {
            if (section.id !== path) return section
            return { ...section, ...updates }
        })
    }
    return { ...x }
}

export function isSection(path: string) {
    return path.split('/').length === 1
}
export function getSectionId(path: string | string[]) {
    const categories = typeof path === 'string' ? path.split('/') : path
    return categories[0]
}

export function shallowRemoveCategoryUnit(path: string, x: CategoryRes | undefined) {
    if (!x) return getEmptyCategoryRes()

    if (isSection(path)) {
        x.categoryUnits.sections = x.categoryUnits.sections.filter((section) => {
            return section.id !== path
        })
    } else {
        const sectionId = getSectionId(path)
        x.categoryUnits.categories[sectionId] = x.categoryUnits.categories[sectionId]?.filter((category) => {
            return !category.id.startsWith(path)
        })
        delete x.categoryUnits.groups[path]
    }
    Object.keys(x.items).forEach((type) => {
        // @ts-expect-error
        x.items[type] = x.items[type].filter((x: Item) => !x.category || !x.category.startsWith(path))
    })
    return { ...x }
}

export const addCategoryNameToItems = <T extends { category: string },>(items: T[], categoryUnits: CategoryUnits | undefined) => {
    return items.map((x) => {
        const categoryNames = pathToNamesArrayGlobal(x.category, categoryUnits)
        const categoryName = categoryNames && (categoryNames.length > 1 ? categoryNames[1] : undefined)
        const colorNum = getCategoryNumGlobal(x.category, categoryUnits)
        return {
            ...x,
            categoryName,
            colorNum,
        }
    })
}
