import { LessonConfig } from './types'

const PRIORITY_SQUARE_CONFIG: LessonConfig = {
    title: 'Priority Square (Eisenhower Matrix)',
    description: `Organize tasks by urgency and importance.\n\n"What is important is seldom urgent and what is urgent is seldom important." \n- Dwight D. Eisenhower`,
    coverImgSrc: 'priority-table.png',
    blocks: [
        {
            type: 'visual',
            id: 'planda',
            content: {
                type: 'image',
                imgSrc: 'priority-table.png',
                imageTitle: 'Organize tasks into columns',
                width: 2314,
                height: 1614,
            },
        },
        {
            type: 'visual',
            id: 'youtube',
            content: {
                type: 'youtube',
                url: 'https://www.youtube.com/embed/tT89OZ7TNwc?si=Ee4_0S8--k7IlaYd',
                width: 560,
                height: 315,
            },
        },
    ],
    featuresUnlocked: ['priority-square'],
    challenges: [
        {
            text: 'Create and complete urgent and important task',
            id: 'urgent-important',
            rewardAmount: 5,
            achievementsRequired: { key: 'urgent-important-tasks', op: 'GEQ', value: 1 },
        },
        {
            text: 'Create an urgent but not important task',
            id: 'urgent',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
        {
            text: 'Create an important but not urgent task',
            id: 'important',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
        {
            text: 'Create a task that is neither urgent nor important',
            id: 'not-urgent-important',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
        {
            text: 'Set the number of days requirement for a task to be considered urgent',
            id: 'urgent-days',
            rewardAmount: 5,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ],
}

export default PRIORITY_SQUARE_CONFIG
