import { apiSlice } from './apiSlice'
import { dynamodbUpdateItemOptimistic } from '@/utils/dynamodb'
import { UpdateItemParams } from 'dynamodb-helpers'
import { Achievement, AchievementId, AchievementRes, MissionId } from '@/types/game'
import { builderHelpers } from './builder'
import { FeatureAccessUpdate, UserFeatureAccess } from '@/types/game/featureAccess'
import { setFeatureAccess } from '../featureAccessSlice'
import { RuleOfThreeDay, RuleOfThreeSettings } from '@/types/techniques'
import { New, NewPartialId, toNewItem } from '@/types/newItem'
import { ALL_LESSONS, LessonId } from '@/features/game/levels/config/lessons/lessons'
import { RequirementId } from '@/features/game/levels/config/types'
import { Wallet, WalletSchema } from '@/types/wallet'
import { CurrentLesson, LessonData } from '@/types/lesson'
import { deductCost } from '@/utils/game/wallet'
import { MS_PER_WEEK } from '@planda/utils'

export const gameApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => {
        const { builderItemGET, builderItemPATCH, builderArrayItemPUT, builderArrayItemPATCH } = builderHelpers(builder)

        return {
            getNextLessonChoices: builder.query<LessonId[], void>({
                query: () => `game/next-lesson-choices`,
            }),
            getWallet: builderItemGET<Wallet | null, void>({
                url: () => `user/wallet`,
                tag: 'wallet',
            }),
            getRuleOfThree: builderItemGET<RuleOfThreeDay | null, string>({
                url: (date) => `rule-of-three/day/${date}`,
                tag: 'rule-of-three',
            }),
            getRuleOfThreeHistory: builderItemGET<Record<string, RuleOfThreeDay> | null, void>({
                url: () => `rule-of-three/full`,
                tag: 'rule-of-three',
            }),
            putRuleOfThree: builder.mutation<void, { day: string; updates: UpdateItemParams<RuleOfThreeDay> }>({
                query: ({ day, updates }) => ({ url: `rule-of-three/day/${day}`, method: 'PATCH', body: updates }),
                invalidatesTags: (_, __, arg) => [{ type: 'rule-of-three', id: arg.day }],
                // TODO: onQueryStarted
            }),
            updateRuleOfThree: builderItemPATCH<NewPartialId<RuleOfThreeDay>, { day: string }>({
                url: ({ day }) => `rule-of-three/day/${day}`,
                keyBy: 'day',
                tag: 'rule-of-three',
                getItemEndpointName: 'getRuleOfThree',
            }),
            getRuleOfThreeSettings: builder.query<RuleOfThreeSettings | null, void>({
                query: () => `rule-of-three/settings`,
            }),
            getFeatureAccess: builderItemGET<UserFeatureAccess>({
                url: (id) => `game/feature-access`,
                tag: 'feature-access',
            }),
            updateFeatureAccess: builder.mutation<void, { updates: FeatureAccessUpdate }>({
                query: (updateParams) => ({
                    url: `game/feature-access`,
                    method: 'PATCH',
                    body: updateParams.updates,
                }),
                invalidatesTags: (_, __, arg) => [{ type: 'feature-access' }],
                async onQueryStarted({ updates }, { dispatch, queryFulfilled }) {
                    const patchedResults = [
                        dispatch(
                            gameApiSlice.util.updateQueryData('getFeatureAccess', undefined, (data) => {
                                return {
                                    ...data,
                                    unlocked: data.unlocked.concat(updates.unlock || []).filter((x) => !updates.lock?.includes(x)),
                                    enabled: data.enabled
                                        .concat(updates.enable || [])
                                        .filter((x) => !updates.lock?.includes(x) && !updates.unlock?.includes(x)),
                                }
                            })
                        ),
                    ]
                    dispatch(setFeatureAccess(updates)) // doesn't have an undo
                    queryFulfilled.catch(() => {
                        patchedResults.map((patchedResult) => patchedResult.undo())
                    })
                },
            }),
            getCurrentLesson: builderItemGET<CurrentLesson | null, void>({
                url: () => `game/lesson/current`,
                tag: 'lesson-data',
            }),
            getLessonsInProgress: builderItemGET<LessonId[], void>({
                url: () => `game/lesson/in-progress`,
                tag: 'lessons-in-progress',
            }),
            unlockLesson: builder.mutation<void, { lessonId: LessonId; isCurrent?: boolean; isLimited?: boolean }>({
                query: (item) => ({
                    url: `game/lesson/unlock`,
                    method: 'PUT',
                    body: item,
                }),
                invalidatesTags: (_, __, { lessonId }) => [{ type: 'lesson-data', id: lessonId }, { type: 'feature-access' }, { type: 'wallet' }],
                onQueryStarted({ lessonId, isCurrent, isLimited }, { dispatch, queryFulfilled }) {
                    if (isCurrent && isLimited) throw new Error('Cannot be both current and limited')
                    let oldCurrentLessonId: LessonId | null = null
                    const patchedResults = [
                        dispatch(
                            gameApiSlice.util.updateQueryData('getFeatureAccess', undefined, (data) => {
                                ALL_LESSONS[lessonId].featuresUnlocked?.forEach((featureId) => {
                                    data.enabled.push(featureId)
                                })
                            })
                        ),
                        // TODO: update wallet if buying a lesson
                    ]

                    if (!isLimited) {
                        patchedResults.push(
                            dispatch(
                                gameApiSlice.util.updateQueryData('getWallet', undefined, (data) => {
                                    return data && ALL_LESSONS[lessonId].costToUnlock && deductCost(data, ALL_LESSONS[lessonId].costToUnlock)
                                })
                            )
                        )
                    }

                    if (isCurrent) {
                        patchedResults.push(
                            dispatch(
                                gameApiSlice.util.updateQueryData('getCurrentLesson', undefined, (data) => {
                                    if (data?.lessonId) {
                                        oldCurrentLessonId = data.lessonId
                                    }
                                    return { lessonId, createdAt: Date.now() }
                                })
                            )
                        )
                    } else {
                        // getLessonsInProgress
                        patchedResults.push(
                            dispatch(
                                gameApiSlice.util.updateQueryData('getLessonsInProgress', undefined, (data) => {
                                    data.push(lessonId)
                                })
                            )
                        )
                    }

                    const res = dispatch(
                        gameApiSlice.util.upsertQueryData(
                            'getLessonData',
                            lessonId,
                            toNewItem({ id: lessonId, challenges: {}, freeTrialUntil: isLimited ? Date.now() + MS_PER_WEEK : undefined })
                        )
                    )

                    if (oldCurrentLessonId) {
                        patchedResults.push(
                            dispatch(
                                gameApiSlice.util.updateQueryData('getLessonData', oldCurrentLessonId, (data) => {
                                    if (data) {
                                        data.completed = Date.now()
                                    }
                                })
                            )
                        )
                    }

                    queryFulfilled.catch(() => {
                        patchedResults.forEach((x) => x.undo())
                    })
                },
            }),
            getLessonData: builderItemGET<LessonData | null, LessonId>({ url: (lessonId) => `game/lesson/${lessonId}`, tag: 'lesson-data' }),
            putLessonData: builder.mutation<void, { lessonId: LessonId; item: New<LessonData> }>({
                query: ({ lessonId, item }) => ({
                    url: `game/lesson/${lessonId}`,
                    method: 'POST',
                    body: item,
                }),
                async onQueryStarted({ lessonId, item }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                            return {
                                updatedAt: Date.now(),
                                createdAt: Date.now(),
                                ...data,
                                ...item,
                            }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                    })
                },
            }),
            claimLessonChallengeReward: builder.mutation<void, { lessonId: LessonId; challengeId: RequirementId }>({
                query: ({ lessonId, challengeId }) => ({
                    url: `game/lesson/${lessonId}/${challengeId}/claim-reward`,
                    method: 'POST',
                }),
                invalidatesTags: (result, error, item) => [{ type: 'lesson-data', id: item.lessonId }, { type: 'wallet' }],
                async onQueryStarted({ lessonId, challengeId }, { dispatch, queryFulfilled }) {
                    const challenge = ALL_LESSONS[lessonId].challenges.find((c) => c.id === challengeId)
                    const patchedResults = [
                        dispatch(
                            gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                                if (!data) return toNewItem({ id: lessonId, challenges: { [challengeId]: { claimedAt: Date.now() } } })
                                data.challenges[challengeId] = { claimedAt: Date.now() }
                            })
                        ),
                        dispatch(
                            gameApiSlice.util.updateQueryData('getWallet', undefined, (data) => {
                                if (!challenge) return data
                                if (!data) return WalletSchema.parse({ balance: challenge.rewardAmount })
                                data.balance += challenge.rewardAmount
                            })
                        ),
                    ]
                    // gameApiSlice.util.updateQueryData('getWallet', undefined, (data) => {
                    //     return { ...data, ...item }
                    // })
                    patchedResults.map((patchedResult) => {
                        queryFulfilled.catch(() => {
                            patchedResult.undo()
                        })
                    })
                },
            }),
            updateLessonData: builder.mutation<void, { lessonId: LessonId; updates: UpdateItemParams<LessonData> }>({
                query: ({ lessonId, updates }) => ({
                    url: `game/lesson/${lessonId}`,
                    method: 'PATCH',
                    body: updates,
                }),
                async onQueryStarted({ lessonId, updates }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getLessonData', lessonId, (data) => {
                            return dynamodbUpdateItemOptimistic(
                                data || {
                                    id: lessonId,
                                    createdAt: Date.now(),
                                    updatedAt: Date.now(),
                                    challenges: {},
                                },
                                updates
                            )
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                    })
                },
            }),
            getAchievements: builder.query<AchievementRes, void>({
                query: () => `game/achievement/all`,
            }),
            getAchievement: builderItemGET<Achievement | null, AchievementId>({
                url: (lessonId) => `game/achievement/${lessonId}`,
                tag: 'achievement',
            }),
            putAchievement: builder.mutation<void, { achievementId: AchievementId; proof?: unknown }>({
                query: ({ achievementId, proof }) => ({
                    url: `game/achievement/${achievementId}`,
                    method: 'PUT',
                    body: proof,
                }),
                invalidatesTags: (result, error, item) => [{ type: 'achievement', id: item.achievementId }, { type: 'achievement' }],
                async onQueryStarted({ achievementId }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getAchievements', undefined, (data) => {
                            if (achievementId in data) {
                                data[achievementId]++
                            } else {
                                data[achievementId] = 1
                            }
                        })
                    )
                    const patchedResult2 = dispatch(
                        gameApiSlice.util.updateQueryData('getAchievement', achievementId, () => {
                            return { id: achievementId, createdAt: Date.now(), updatedAt: Date.now(), count: 1 }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                        patchedResult2.undo()
                    })
                },
            }),
            getMissions: builder.query<Set<MissionId>, void>({
                query: () => `game/achievement/all`,
                transformResponse: function (baseQueryReturnValue, meta, arg) {
                    if (!Array.isArray(baseQueryReturnValue)) {
                        throw new Error('getMission expected array')
                    }
                    return new Set(baseQueryReturnValue as MissionId[])
                },
            }),
            getMission: builder.query<Record<string, any>, MissionId>({
                query: (lessonId) => `game/achievement/${lessonId}`,
            }),
            putMission: builder.mutation<void, { missionId: MissionId; proof?: Record<string, any> }>({
                query: ({ missionId, proof }) => ({
                    url: `game/achievement/${missionId}`,
                    method: 'POST',
                    body: proof,
                }),
                async onQueryStarted({ missionId }, { dispatch, queryFulfilled }) {
                    const patchedResult = dispatch(
                        gameApiSlice.util.updateQueryData('getMissions', undefined, (data) => {
                            data.add(missionId)
                        })
                    )
                    const patchedResult2 = dispatch(
                        gameApiSlice.util.updateQueryData('getMission', missionId, (data) => {
                            return { createdAt: Date.now() }
                        })
                    )
                    queryFulfilled.catch(() => {
                        patchedResult.undo()
                        patchedResult2.undo()
                    })
                },
            }),
        }
    },
})
