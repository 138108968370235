import z from "src/lib/zod";

export const LinkSchema = z.object({
    text: z.string().optional(),
    url: z.string().url(),
})

export type Link = z.infer<typeof LinkSchema>

export const LocationSchema = z.object({
    coordinates: z.object({
        lat: z.number(),
        lon: z.number(),
    }).optional(),
    name: z.string(),
})

export const PersonSchema = z.object({
    name: z.string(),
    email: z.string().email().optional(),
    description: z.string().optional(),
    contactType: z.string().optional(),
})

export type Person = z.infer<typeof PersonSchema>

// export const OfficeHourSchema = TemplateRecurSchema
