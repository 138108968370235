import { LessonConfig } from './types'

const HABIT_TRACKER_CONFIG: LessonConfig = {
    title: 'Habit Tracker',
    description: 'We are what we repeatedly do. Excellence, then, is not an act, but a habit. — Will Durant\n\nHabit Tracker system from the book The End of Procrastination by Petr Ludwig',
    costToUnlock: { amount: 10, currency: 'coins' },
    // visual: {
    //     type: 'image',
    //     imgSrc: 'coming-soon.jpg',
    //     width: 1280,
    //     height: 854,
    // },
    coverImgSrc: 'habit-tracker.png',
    blocks: [
        {
            type: 'visual',
            content: {
                type: 'image',
                imgSrc: 'habit-tracker.png',
                width: 1200,
                height: 500,
                maxWidth: 1000,
            },
        },
        {
            type: 'bullets',
            content: [
                'Click a circle to change its color.',
                'Red: incomplete',
                'Green: complete',
                'Grey: not applicable',
                'Click the + button to add a new habit.',
            ],
        },
    ],
    featuresUnlocked: ['habit-tracker'],
    challenges: [
        {
            text: 'Reach a 7-day streak',
            id: 'habit-tracker-7-day-streak',
            rewardAmount: 30,
            achievementsRequired: 'UNIMPLEMENTED',
        },
    ],
}

export default HABIT_TRACKER_CONFIG
