import EventCronParser from 'event-cron-parser'
import { z } from 'zod'

export const cronSchema = z.custom<string>((val: any) => {
    if (typeof val !== 'string') return false
    try {
        new EventCronParser(val).validate()
        return true
    } catch (e) {
        console.error('invalid cron', e, val)
        return false
    }
})

export const clientArrayToDBSetSchema = <T extends string>(Enum: z.ZodType<T>) => z.preprocess((x) => (x instanceof Set ? [...x] : x), z.array(Enum)) // should be stored as a set
export const clientArrayToDBSetSchemaOptional = <T extends string>(Enum: z.ZodType<T>) =>
    z.preprocess((x) => (x instanceof Set ? [...x] : x), z.array(Enum).optional()) // should be stored as a set

export default z
