import {
    Item,
    TemplateDB,
    Event,
    TemplateRecur,
    TodoGroup,
    Task,
    DatedTask,
    TemplateDBSchema,
    TemplateRecurSchema,
    EventSchema,
    TaskSchema,
} from 'src/types'
import { Section, Category, SectionSchema, CategorySchema, TodoGroupSchema } from '@/types/categories'
import { z } from 'zod'

export const ItemsByTypeOfflineSchema = z.object({
    templateDB: TemplateDBSchema.array(),
    templateRecur: TemplateRecurSchema.array(),
    event: EventSchema.pick({
        name: true,
        dateStart: true,
        dateEnd: true,
        category: true,
        id: true,
        priority: true,
        completed: true,
    }).array(),
    task: TaskSchema.pick({
        id: true,
        name: true,
        dateStart: true,
        category: true,
        order: true,
        priority: true,
        status: true,
        completed: true,
    }).array(),
})

export type ItemsByTypeOffline = z.infer<typeof ItemsByTypeOfflineSchema>

export type ItemsByType = {
    templateDB: TemplateDB[]
    templateRecur: TemplateRecur[]
    event: Event[]
    task: Task[]
}

export type Res = Partial<{
    items: ItemsByType
    parentIdDict: { [x: string]: Item }
}>

export interface ItemsByCategory {
    [sectionId: string]: {
        [categoryId: string]: Item[]
    }
}

export interface TasksByCategory {
    [sectionId: string]: {
        [categoryId: string]: Task[]
    }
}

export interface EventsByCategory {
    [sectionId: string]: {
        [categoryId: string]: Event[]
    }
}

export interface TemplateDBByCategory {
    [sectionId: string]: {
        [categoryId: string]: TemplateDB[]
    }
}

export type ParentIdDict = Record<string, Task | TemplateRecur> // for recurring events + taskEvents
export type CategoryRes = {
    categoryUnits: CategoryUnits
    parentIdDict: ParentIdDict
    items: ItemsByType
}

export const getEmptyCategoryRes = (): CategoryRes => {
    return {
        categoryUnits: {
            sections: [],
            categories: {},
            groups: {},
        },
        parentIdDict: {},
        items: {
            templateDB: [],
            templateRecur: [],
            event: [],
            task: [],
        },
    }
}

export type DatedRes = (TemplateRecur | Event | DatedTask)[]

// below two are together
export const CategoryUnitsSchema = z.object({
    sections: z.array(SectionSchema),
    categories: z.record(z.array(CategorySchema)),
    groups: z.record(z.array(TodoGroupSchema)),
})

export type CategoryUnits = {
    sections: Section[]
    categories: Record<string, Category[]>
    groups: Record<string, TodoGroup[]>
}
