import z from 'src/lib/zod'
import { LinkSchema, PersonSchema } from './input'
// import { LinkSchema, PersonSchema } from './input'
// import { TemplateRecurSchema } from './item'
// // do groups have to be part of section? can you just make a group global and have it apply to every category? probably

export const CategoryUnitSchema = z.object({
    active: z.number(),
    name: z.string().min(1),
    id: z.string().min(1),
    updatedAt: z.number(),
    createdAt: z.number(),
    notes: z.string().optional(),
}).passthrough()

export const TodoGroupSchema = CategoryUnitSchema.omit({ active: true }).strict().extend({
    // id: GROUP.sectionId/categoryId/groupId -> sectionId/categoryId/groupId
    templateDBId: z.string().optional(),
    gradingWeightInPercentage: z.number().optional(),
    gradeInPercentage: z.number().optional(),
    type: z.string().optional()
})
export type TodoGroup = z.infer<typeof TodoGroupSchema>

export const SectionSchema = CategoryUnitSchema.extend({
    // active: 0.SECTION or 1.SECTION
    // id: SECTION.sectionId -> sectionId
})

export const CategorySchema = CategoryUnitSchema.extend({
    // active: 0.sectionId or 1.sectionId
    // id: CATEGORY.sectionId/categoryId -> sectionId/categoryId
    colorNum: z.number(),
    contacts: z.array(PersonSchema).optional(),
    profs: z.array(PersonSchema).optional(),
    tas: z.array(PersonSchema).optional(),
    links: z.array(LinkSchema).optional(),
    syllabusKey: z.string().optional(),
    defaultDueTime: z.number().optional(),
    // TODO: where does officeHours go
})
// export const GroupSchema = CategoryUnitSchema.extend({
//     color: z.string().optional() // IDK could be number instead of string
// })

// export const CategorySchema = CategoryUnitSchema.extend({
//     colorNum: z.number(),
//     profs: z.array(PersonSchema).optional(),
//     tas: z.array(PersonSchema).optional(),
//     officeHours: z.array(z.string()).optional(), // string of templateRecur ids
//     links: z.array(LinkSchema).optional()
// })

// export const SectionSchema = CategoryUnitSchema.extend({
//     next: z.record(z.string(), CategorySchema), // key is categoryId
//     active: z.array(z.string().or(z.null())).length(9), // 1-indexed
// })

// export type Group = z.infer<typeof GroupSchema>
export type Section = z.infer<typeof SectionSchema>
export type Category = z.infer<typeof CategorySchema>
export type CategoryUnit = z.infer<typeof CategoryUnitSchema>

// export const CategoriesSchema = z.object({
//     next: z.record(z.string(), SectionSchema), // key is sectionId
//     active: z.set(z.string()),
// })


// export type Categories = z.infer<typeof CategoriesSchema>


export const getInfoFromCategoryPath = (path?: string) => {
    const isSection = !path;
    const isCategory = path && path.split("/").length === 1;
    const isGroup = path && path.split("/").length === 2;
    return { isSection, isCategory, isGroup };
};
